<template>
  <b-form>
    <b-card title="Banka Pos Ekle">
      <b-form-group
        label="Banka"
        label-for="banks"
      >
        <v-select
          id="banks"
          v-model="posConfig.id_banks"
          :options="banks"
          label="title"
          :reduce="bank => bank.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group
        label="Sanal Pos"
        label-for="pos_types"
      >
        <v-select
          id="pos_types"
          v-model="posConfig.id_pos_types"
          :options="posTypes"
          label="title"
          :reduce="posType => posType.id"
          placeholder="Seçiniz"
          disabled
        />
      </b-form-group>
      <b-form-group
        label="Varsayılan Banka"
        label-for="default"
      >
        <b-form-checkbox
          id="default"
          v-model="posConfig.default"
          name="check-button"
          switch
          inline
        />
      </b-form-group>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <b-form-checkbox
          id="status"
          v-model="posConfig.status"
          name="check-button"
          switch
          inline
        />
      </b-form-group>
      <b-form-group
        label="3D Ödeme"
        label-for="pay3d"
      >
        <b-form-checkbox
          id="pay3d"
          v-model="posConfig.pay3d"
          name="check-button"
          switch
          inline
        />
      </b-form-group>
    </b-card>
    <nestpay v-if="posConfig.id_pos_types === '1'" />
    <gvp v-if="posConfig.id_pos_types === '2'" />
    <get724 v-if="posConfig.id_pos_types === '3'" />
    <posnet v-if="posConfig.id_pos_types === '4'" />
    <payfor v-if="posConfig.id_pos_types === '5'" />
    <installments />
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormCheckbox,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import Nestpay from '@/views/PosConfigs/Configs/Nestpay'
// eslint-disable-next-line import/extensions
import Gvp from '@/views/PosConfigs/Configs/Gvp'
// eslint-disable-next-line import/extensions
import Installments from '@/views/PosConfigs/Configs/Installments'
// eslint-disable-next-line import/extensions
import Get724 from '@/views/PosConfigs/Configs/Get724'
// eslint-disable-next-line import/extensions
import Posnet from '@/views/PosConfigs/Configs/Posnet'
// eslint-disable-next-line import/extensions
import Payfor from '@/views/PosConfigs/Configs/Payfor'

export default {
  name: 'Edit',
  components: {
    BForm,
    BCard,
    BFormCheckbox,
    BFormGroup,
    BButton,
    vSelect,
    Nestpay,
    Gvp,
    Installments,
    Get724,
    Posnet,
    Payfor,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    banks() {
      return this.$store.getters['banks/getBanks']
    },
    posTypes() {
      return this.$store.getters['posConfigs/getPosTypes']
    },
    posConfig() {
      return this.$store.getters['posConfigs/getPos_config']
    },
    saveStatus() {
      return this.$store.getters['posConfigs/getPos_configSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getBanks()
    this.getPosTypes()
    this.getPosConfig()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getBanks() {
      this.$store.dispatch('banks/banks')
    },
    getPosTypes() {
      this.$store.dispatch('posConfigs/pos_types')
    },
    getPosConfig() {
      this.$store.dispatch('posConfigs/pos_configView', this.$route.params.id)
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('posConfigs/pos_configSave', this.posConfig)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
